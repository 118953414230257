import React, {useState} from "react"
import WhatsAppButton from "../WhatsAppButton"
import ComparisonButton from "../ComparisonButton"
import WishListButton from "../WishListButton"
import CartButton from "../CartButton"
import {useRouter} from "next/router"
import Image from "next/image"
import Search from "../../reusable/Search"

type Props = {
    isFrontPage?: boolean
    whatsappBtnBgColor?:string
}
function NavBarCtas({isFrontPage, whatsappBtnBgColor = null}: Props) {
	const router = useRouter()
	const isBrandsPage = router.pathname.startsWith("/brand-world")
	const [isSearchActivated, setIsSearchActivated] = useState<boolean>()
	return (
		<div className={
			"d-flex align-items-center justify-content-start gap-2"}>
			{isBrandsPage ? <div className={"d-none d-md-block me-2"}
			>

				<Search
					hideNavIcon
					isBrandsPage
					hideAi />

			</div> : null}
			<div className="me-2">
				<WhatsAppButton classes={isBrandsPage ? "d-none d-md-block" : ""}
					bgColor={whatsappBtnBgColor} />
			</div>
			<ComparisonButton isBrandsPage={isBrandsPage} />
			<WishListButton isBrandsPage={isBrandsPage} />
			<CartButton
				isBrandsPage={isBrandsPage}
				isFrontpage={isFrontPage}
			/>
		</div>
	)
}

export default NavBarCtas
