import React, {useContext, useState} from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import CartIndicator from "../reusable/CartIndicator"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import styles from "./navigation.module.scss"
import Image from "next/image"
import EmptyCartSection from "../reusable/EmptyCartSection"
import CartContext from "../../context/CartContext"
import AnimationComponent from "../reusable/components/AnimationComponent/AnimationComponent"
import LayoutContext from "../../context/LayoutContext"

type Props = {
    isFrontpage?: boolean
    mobile?: boolean
    isBrandsPage?: boolean
}

const CartButton: React.FC<Props> = ({mobile, isBrandsPage}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	const [showEmptyCartDropdown, setShowEmptyCartDropdown] = useState<boolean>(false)
	const {items} = useContext(CartContext)
	const {isMobile} = useContext(LayoutContext)
	return (
		<Link href={"/checkout"}
			as={t("/checkout")}>
			<a onMouseEnter={() => {
				setShowEmptyCartDropdown(items?.length === 0)
			}}
			className={`${mobile ? `btn position-relative shadow-none ${styles.ctaGreyHover}` : "position-relative"} ${isBrandsPage ? "" : styles.hoverEffect} p-2 rounded d-flex align-items-center justify-content-center`}
			onClick={() => {
				reactGA?.event({
					category: gaCategories.cart,
					action: gaEvents.seeCart,
					label: gaEvents.seeCart,
					nonInteraction: false
				})
			}}>
				<Image layout="fixed"
					src={isBrandsPage ? "/assets/icons/cart-white.svg" : "/assets/icons/cart-icon.svg"}
					width={24}
					height={24}
					alt="cart icon"/>
				<CartIndicator />
				<AnimationComponent inProp={showEmptyCartDropdown && !isMobile}
					type="fade">
					<EmptyCartSection setShowDropdown={setShowEmptyCartDropdown} />
				</AnimationComponent>
			</a>
		</Link>
	)
}

CartButton.propTypes = {
	isFrontpage: PropTypes.bool,
	mobile: PropTypes.bool
}

export default CartButton
