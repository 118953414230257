import React, {useContext} from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import styles from "./navigation.module.scss"
import Image from "next/image"
import {useRouter} from "next/router"
import FirebaseContext from "../../context/FirebaseContext"
import NotificationIndicator from "./NotificationIndicator"
import LayoutContext from "../../context/LayoutContext"
import WishListContext from "../../context/WishListContext"
import ListQuantityIndicator from "../reusable/ListQuantityIndicator"
type Props = {
	isBrandsPage?:boolean
	}
const WishListButton: React.FC<Props> = ({isBrandsPage}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	const {user} = useContext(FirebaseContext)
	const {isMobile: mobile} = useContext(LayoutContext)
	const {wishList} = useContext(WishListContext)
	const router = useRouter()
	const active = router.asPath === `${t("/wish-list")}/${user?.uid}`
	return (
		<Link className="position-relative"
			href={`/wish-list/${user?.uid}`}
			as={t("/wish-list") + `/${user?.uid}`}>
			<a className={` col-12 ${mobile ? "btn shadow-none" : ""} ${isBrandsPage ? "" : styles.hoverEffect} ${styles.listIconBg} ${active ? styles.link_active : ""}`}
				onClick={() => {
					reactGA?.event({
						category: gaCategories.wishList,
						action: gaEvents.wishListVisited,
						label: gaEvents.wishListVisited,
						nonInteraction: false
					})
				}}>
				<Image src={isBrandsPage ? "/assets/icons/heart-white.svg" : "/assets/icons/Heart.svg"}
					width={24}
					height={24}
					alt="Wish list icon" />
				<ListQuantityIndicator listLength={wishList.length}/>
				<NotificationIndicator mobile={mobile} />
			</a>
		</Link>

	)
}

WishListButton.propTypes = {
	isBrandsPage: PropTypes.bool
}

export default WishListButton
