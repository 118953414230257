import React, {useContext} from "react"
import PropTypes from "prop-types"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"
import {getStars} from "../../utility/Helper"
import Link from "next/link"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"

type Props = {
  reviews: any
  justifyContent?: string
  paddingX?: string
  starWidth?: number
  googleHeight?: number
  googleWidth?: number
  reviewCountSize?: number
}

const GoogleRbox: React.FC<Props> = ({
	reviews,
	googleWidth = 18,
	googleHeight = 18,
	starWidth = 12,
	reviewCountSize = 14,
	justifyContent = "center",
	paddingX = "3"
}) => {
	const {averageRating, totalReviewCount} = reviews
	const stars = getStars(averageRating, starWidth)
	const {reactGA} = useContext(AnalyticsContext)

	return (
		< >
			{
				reviews ? (
					<>
						<div className="d-none d-md-block">
							<Link
								href={"https://www.google.com/search?q=mybikeplan&rlz=1C5CHFA_enCH967CH967&oq=mybikeplan&aqs=chrome.0.69i59j69i60j69i61j69i60l4j69i65.1718j0j4&sourceid=chrome&ie=UTF-8#lrd=0x47900b8ca43e3fad:0x819e21cbe9f33d14,1"}>
								<a onClick={() => {
									reactGA.event({
										category: gaCategories.homePage,
										action: gaEvents.googleReviews,
										label: gaEvents.googleReviews,
										nonInteraction: false
									})
								}}
								className={"row mt-4 mt-lg-2 justify-content-center justify-content-lg-center pointer"}
								target={"_blank"}>
									<div className="col-auto">
										<div
											className="w-auto row rounded justify-content-left align-items-center px-3 g-0 pt-1">
											<div className="col-auto d-flex align-items-center">
												<Image src={"/assets/icons/google-small-icon.svg"}
													width={"16"}
													height={"16"}/>
											</div>
											<div className="col-auto px-1">
												<div className="row justify-items-center g-0">
													{stars.map(star => {
														return star
													})}
												</div>
											</div>
											<div className="col-auto px1">
												<Typography className={" d-inline text-black"}
													variant={"bodyLg"}
													semanticTag={"p"}>
													{`(${totalReviewCount}+) `}
													<b className="text-bold"
														style={{
															fontSize: "1.2rem"
														}}>{averageRating}</b>
												</Typography>
											</div>
										</div>
									</div>
								</a>
							</Link>
						</div>
						<div className="d-block d-md-none">
							<div className="row">
								<div className="col-12">
									<Link
										href={"https://www.google.com/search?q=mybikeplan&rlz=1C5CHFA_enCH967CH967&oq=mybikeplan&aqs=chrome.0.69i59j69i60j69i61j69i60l4j69i65.1718j0j4&sourceid=chrome&ie=UTF-8#lrd=0x47900b8ca43e3fad:0x819e21cbe9f33d14,1"}>
										<a onClick={() => {
											reactGA.event({
												category: gaCategories.homePage,
												action: gaEvents.googleReviews,
												label: gaEvents.googleReviews,
												nonInteraction: false
											})
										}}
										className={`row justify-content-${justifyContent} pointer text-decoration-none`}
										target={"_blank"}>
											<div className="col-auto">
												<div
													className={`w-auto row rounded align-items-center justify-content-left p-${paddingX} g-0 py-1 pt-2`}>
													<div className="col-auto d-flex align-items-center">
														<Image src={"/assets/icons/google-small-icon.svg"}
															width={googleWidth}
															height={googleHeight}/>
													</div>
													<div className="col-auto d-flex align-items-center"
														style={{marginLeft: 6}}>
														<div className="row align-items-center justify-items-center g-0"
															style={{height: "24px"}}>
															{stars.map(star => {
																return star
															})}
														</div>
													</div>
													<div className="col-auto px-1 ">
														<Typography className={" d-inline text-black"}
															variant={"bodySm"}
															semanticTag={"p"}
															style={{
																fontSize: reviewCountSize
															}}>
															{`(${totalReviewCount}+) `}
															<b className="text-bold"
																style={{
																	fontSize: reviewCountSize,
																	marginLeft: 6
																}}>{averageRating}</b>
														</Typography>
													</div>
												</div>
											</div>
										</a>
									</Link>
								</div>
							</div>
						</div>
					</>
				) : null
			}
		</>

	)
}

GoogleRbox.propTypes = {
	reviews: PropTypes.any
}

export default GoogleRbox
