import React, {useContext, useState} from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import styles from "./navigation.module.scss"
import Image from "next/image"
import {useRouter} from "next/router"
import LayoutContext from "../../context/LayoutContext"
import DataContext from "../../context/DataContext"
import ListQuantityIndicator from "../reusable/ListQuantityIndicator"
import Typography from "../reusable/Typography/Typography"

type Props = {
isBrandsPage?:boolean
}

const ComparisonButton: React.FC<Props> = ({isBrandsPage}) => {
	const {t} = useTranslation()
	const {isMobile: mobile} = useContext(LayoutContext)
	const router = useRouter()
	const active = router.asPath === `${t("/comparison-page")}`
	const {comparisonProducts} = useContext(DataContext)
	const [showTooltip, setShowTooltip] = useState(false)

	return (
		<Link className="position-relative"
			href={t("/comparison-page")}>
			<a className={`col-12 ${mobile ? "btn shadow-none" : ""} 
                ${isBrandsPage ? "d-md-block d-none" : styles.hoverEffect} 
                ${styles.listIconBg} 
                ${active ? styles.link_active : ""}
            `}>
				<Image src={isBrandsPage ? "/assets/icons/comparison-white.svg" : "/assets/icons/compare-bikes-icon.svg"}
					width={24}
					height={24}
					alt="Comparison icon"
					onMouseOver={() => setShowTooltip(true)}
					onMouseLeave={() => setShowTooltip(false)}
				/>
				{comparisonProducts.length > 0 ?
					<ListQuantityIndicator listLength={comparisonProducts.length}/> :
					<div className={`text-dark ${styles.emptyListNotification} ${showTooltip ? styles.visible : ""}`}>
						<Typography variant="bodySmBold">{t("empty-list")}</Typography>
						<Typography variant="bodySm">{t("start-adding-items")}</Typography>
					</div>
				}
			</a>
		</Link>

	)
}

export default ComparisonButton
