import React, {useContext, useEffect, useState} from "react"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"
import Button from "../reusable/components/Button"
import {X} from "react-bootstrap-icons"
import WishListContext from "../../context/WishListContext"
import FirebaseContext from "../../context/FirebaseContext"
import {useTranslation} from "next-i18next"

type Props = {
    mobile: boolean
}

function NotificationIndicator({mobile}:Props) {
	const {t} = useTranslation()
	const {latestItemAdded, resetNotifications} = useContext(WishListContext)
	const {user} = useContext(FirebaseContext)
	const [progress, setProgress] = useState(100)

	useEffect(() => {
		if (latestItemAdded) {
			setProgress(() => 100)

			const interval = setInterval(() => {
				setProgress(prevProgress => {
					if (prevProgress > 0) {
						return prevProgress - 1
					}

					clearInterval(interval)
					return 0
				})
			}, 5000 / 100)

			return () => clearInterval(interval)
		}
	}, [latestItemAdded])

	const percentage = `${progress}%`

	if (latestItemAdded) {
		return (
			<div style={{borderRadius: "4px", backgroundColor: "white", transform: mobile ? "translateX(-50%)" : "translateX(-85%)", padding: "16px 16px 8px 16px", width: mobile ? "95dvw" : "320px", minHeight: "143px", zIndex: 99999, top: mobile ? "70px" : null, bottom: mobile ? "" : "-160px", border: "1px solid #DEE2E6", rowGap: "20px", left: mobile ? "50%" : "0", position: mobile ? "fixed" : "absolute"}}
				className="fade-in  d-flex flex-column align-items-start justify-content-start">
				<div className="d-flex  col-12 text-black justify-content-start align-items-center gap-2">
					<Image alt={latestItemAdded.name}
						src={latestItemAdded?.images[0]?.src || latestItemAdded?.image}
						width={40}
						height={40} />
					<div style={{rowGap: "4px"}}
						className="d-flex flex-column position-relative justify-content-starrt align-items-start ">
						<Typography variant="bodySm"
							style={{fontSize: "14px", lineHeight: "20px", fontWeight: 700}}>{latestItemAdded?.name}</Typography>
						<div className="d-flex align-items-center gap-2 justify-content-start">
							<Image alt="green check mark"
								src="/assets/icons/checkout/check-green.svg"
								width={14}
								height={14}/>
							<Typography variant="bodySm"
								style={{fontSize: "14px", lineHeight: "20px"}}>{t("added-to-wishlist-label")}</Typography>
						</div>
					</div>
					<X onClick={e => {
						e.preventDefault()
						e.stopPropagation()
						resetNotifications()
					}}
					style={{position: "absolute", top: "4px", right: "3px"}}
					size={24}
					color={"#cccccc"} />
				</div>
				<Button bgColor="#FEC04D"
					className="col-12"
					href={t("/wish-list") + `/${user?.uid}`}
					fontSizeSmall
					icon={<Image alt="Heart"
						src="/assets/icons/Heart.svg"
						width={24}
						height={24} />}
					label={t("go-to-wishlist")} />
				<div style={{height: "4px", width: "111.15%", marginBottom: "-8px", marginLeft: "-16px"}}
					className="progress bg-white">
					<div className="progress-bar bg-black "
						role="progressbar"
						aria-valuenow={90}
						aria-valuemin={0}
						aria-valuemax={100}
						style={{width: `${percentage}`}}>
					</div>
				</div>
			</div>
		)
	}

	return <></>
}

export default NotificationIndicator
