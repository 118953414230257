import React, {Dispatch, SetStateAction} from "react"
import Typography from "./Typography/Typography"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import {useRouter} from "next/router"
import Link from "next/link"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"
import {X} from "react-bootstrap-icons"

type Props = {
	links?: { label: string, href: string }[]
	setShowDropdown?: Dispatch<SetStateAction<boolean>>
}

function EmptyCartSection({links, setShowDropdown}:Props) {
	const {t} = useTranslation()
	const router = useRouter()
	const {locale} = router
	return (
		<div onMouseLeave={() => {
			setShowDropdown(false)
		}}
		style={{width: "286px", minHeight: "266px", zIndex: "99999", borderRadius: "16px", transform: "translateY(100%)", bottom: "-19px", right: 0, position: "absolute", border: "1px solid #DEE2E6", padding: "16px"}}
		className="bg-white d-flex align-items-start justify-content-start flex-column p-4">

			<div style={{rowGap: "4px", marginTop: "-6px", marginBottom: "-7px"}}
				className="d-flex position-relative align-items-start justify-content-start flex-column ">
				<X onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					setShowDropdown(false)
				}}
				style={{top: "-8px", right: "-3.5rem", position: "absolute"}}
				size={22}
				color="secondary" />
				<Typography className="d-flex align-items-center justify-content-center gap-2"
					variant="bodySmBold"
					semanticTag="h1"
					style={{fontSize: "14px", color: "#212529", lineHeight: "20px", fontWeight: 700, padding: "6px 8px"}}> <span>😲</span>   {t("your-cart-empty-label")}</Typography>
				<Typography variant="bodySmBold"
					semanticTag="h1"
					style={{fontSize: "14px", lineHeight: "20px", fontWeight: 500, color: "#6C7074", padding: "6px 8px"}}>{`${t("start-here")}:`}</Typography>
			</div>
			<div style={{rowGap: "4px"}}
				className="d-flex align-items-start col-12 justify-content-start flex-column">
				{links?.[locale]?.map(({label, href}, index) => {
					const url = filterUrl({[urlParams.categories]: href}, urlParams.categories, href)

					if (label !== "Alle Marken") {
						return <Link href={url}
							key={index}
							passHref><a style={{borderRadius: "4px", height: "36px", padding: "6px 8px"}}
								className="d-flex  link-hover col-12 align-items-center link-reset justify-content-between"><Typography variant="bodySmBold"
									semanticTag="span"
									style={{fontSize: "14px", fontWeight: 500, color: "#212529"}}>{t(label)}</Typography> <Image alt="chevron"
									src="/assets/icons/chevron-right.svg"
									layout="fixed"
									width={24}
									height={24} /></a></Link>
					}

					return <Link href={href}
						key={index}
						passHref><a style={{borderRadius: "4px", height: "36px", padding: "6px 8px"}}
							className="d-flex  link-hover col-12 align-items-center link-reset justify-content-between"><Typography variant="bodySmBold"
								semanticTag="span"
								style={{fontSize: "14px", fontWeight: 500, color: "#212529"}}>{t(label)}</Typography> <Image alt="chevron"
								src="/assets/icons/chevron-right.svg"
								layout="fixed"
								width={24}
								height={24} /></a></Link>
				})}
			</div>
		</div>
	)
}

EmptyCartSection.defaultProps = {
	links: {de: [
		{label: "Alle E-Bikes", href: "e-bikes"},
		{label: "Bikes", href: "nicht-elektrische-bikes"},
		{label: "All Accessories", href: "zubehoer"},
		{label: "Alle Marken", href: "/alle-marken"}
	],
	fr: [
		{label: "Alle E-Bikes", href: "e-bikes"},
		{label: "Bikes", href: "velos-non-electriques"},
		{label: "All Accessories", href: "accessoires"},
		{label: "Alle Marken", href: "/fr/alle-marken"}

	]}
}
export default EmptyCartSection
